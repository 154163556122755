import React, { createContext, useCallback, useReducer } from 'react'
import PropTypes from 'prop-types'

const type = {
  NAV: 'NAV',
  ANIMATION: 'ANIMATION',
  MODAL: 'MODAL',
  SERVICEMODAL: 'SERVICEMODAL',
  NEWSMODAL: 'NEWSMODAL',
  PORTFOLIODETAILSMODAL: 'PORTFOLIODETAILSMODAL'
}
const {
  NAV,
  ANIMATION,
  MODAL,
  SERVICEMODAL,
  NEWSMODAL,
  PORTFOLIODETAILSMODAL
} = type

const initialState = {
  nav: '',
  animation: 'fadeInLeft',
  modal: false,
  serviceModal: null,
  newsModal: null,
  portfolioDetailsModal: null,
  menus: [
    { id: 1, name: '__main__', href: '' },
    { id: 2, name: '__about__', href: 'about' },
    { id: 3, name: '__services__', href: 'services' },
    // { id: 4, name: '__portfolio__', href: '/portfolio' },
    // { id: 5, name: '__news__', href: '/news' },
    { id: 6, name: '__contact__', href: 'contact' }
  ]
}

const M1FContext = createContext(initialState)

// Reducer
const reducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case NAV:
      return {
        ...state,
        nav: payload
      }
    case ANIMATION:
      return {
        ...state,
        animation: payload
      }
    case MODAL:
      return {
        ...state,
        modal: payload
      }
    case SERVICEMODAL:
      return {
        ...state,
        serviceModal: payload
      }
    case NEWSMODAL:
      return {
        ...state,
        newsModal: payload
      }
    case PORTFOLIODETAILSMODAL:
      return {
        ...state,
        portfolioDetailsModal: payload
      }
    default:
      return state
  }
}

// Watson State
const M1FState = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const navChange = useCallback((value) => {
    dispatch({
      type: NAV,
      payload: value
    })
  }, [])

  const animationChange = useCallback((value) => {
    dispatch({
      type: ANIMATION,
      payload: value
    })
  }, [])

  const modalToggle = useCallback((value) => {
    dispatch({
      type: MODAL,
      payload: value
    })
  }, [])

  const setServiceModal = useCallback((value) => {
    dispatch({
      type: SERVICEMODAL,
      payload: value
    })
  }, [])
  const setNewsModal = useCallback((value) => {
    dispatch({
      type: NEWSMODAL,
      payload: value
    })
  }, [])
  const setPortfolioDetailsModal = useCallback((value) => {
    dispatch({
      type: PORTFOLIODETAILSMODAL,
      payload: value
    })
  }, [])
  const {
    nav,
    animation,
    modal,
    serviceModal,
    newsModal,
    portfolioDetailsModal,
    menus
  } = state
  return (
    <M1FContext.Provider
      value={{
        menus,
        nav,
        navChange,
        animation,
        animationChange,
        modal,
        modalToggle,
        serviceModal,
        setServiceModal,
        newsModal,
        setNewsModal,
        portfolioDetailsModal,
        setPortfolioDetailsModal
      }}
    >
      {children}
    </M1FContext.Provider>
  )
}

M1FState.propTypes = {
  children: PropTypes.node.isRequired
}

export default M1FState
export { M1FContext }
